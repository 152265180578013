<template>
  <div class="s-layout" :style="colorObject">
    <div class="s-container">
      <Header
        :meta="meta"
        :page-title="moduleConfig.page_title"
        :page-image="moduleConfig.page_image"
        :page-kv-image="moduleConfig.page_kv_image"
        :header-section="moduleConfig.header_section"
      />
      <div class="content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import themeColor from "@/mixins/liff/themeColor";
import module from "@/mixins/liff/branch/main";
import Header from "@/components/Page/Liff/Shared/HeaderV3";

export default {
  mixins: [themeColor, module],
  components: {
    Header,
  },
  computed: {
    ...mapGetters({
      themeConfig: "liffGeneral/themeConfig",
    }),
  },
  data() {
    return {
      themeConfigPage: "branch",
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/liff/main.css";
@import "../../../assets/css/liff/liff_init_theme_config.css";

.s-layout,
.s-layout *,
.s-layout *:before,
.s-layout *:after {
  --s-secondary: #de006f;
  --s-warning: #ed6c00;
  --s-danger: #fe0000;
  --s-gray-darker: #363636;
  --s-gray-dark: #2c2c2e;
  --s-gray: #979797 !important;
  --s-gray-light: #c0c4cc;
  --s-gray-lighter: #f2f2f7;
}

.s-layout {
  color: var(--s-gray-dark);
  background-color: var(--liff-layout_bg_color);
  min-height: 100vh;
}

.s-container {
  padding-bottom: 1.5rem;
}

.content {
  padding: 16px 12px;
  background: var(--liff-content_bg_color);
}

::v-deep .page {
  padding: 16px 12px;
  background: var(--liff-content_bg_color);
  &__title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
  }
  &__desc {
    font-size: 14px;
    line-height: 22px;
    color: var(--liff-secondary_text_color);
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    b {
      color: var(--s-primary);
      font-weight: 600;
    }
  }
  &__content {
    margin: 10px 20px;
  }
}
::v-deep .footer{
  max-width: 768px;
  margin: auto;
}
</style>
